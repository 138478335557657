import { BrowserRouter as Router } from 'react-router-dom';
import './assets/css/bootstrap.min.css';
import './assets/css/icofont.min.css';
import './assets/css/responsive.css';
import './assets/css/style.css';
import Routing from './routes';

export default function App() {
  return (
    <>
      <Router>
        <Routing />
      </Router>
    </>
  );
}
