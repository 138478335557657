import React, { useState } from 'react';

const Main = ({ gredient, video }) => {
  const [tog, setTog] = useState();

  return (
    <>
      <section className='row_am pricing_section' id='pricing'>
        <div className='container'>
          <div
            className='section_title'
            data-aos='fade-up'
            data-aos-duration='1500'
            data-aos-delay='300'
          >
            <h2>
              Profile <span>pricing</span>
            </h2>
            <p>
              Our pricing options are designed to cater to your unique needs,
              whether you're a casual enthusiast or a dedicated astrology lover.
            </p>
          </div>
          <div
            className='toggle_block'
            data-aos='fade-up'
            data-aos-duration='1500'
          >
            <span className={`month ${tog ? 'active' : ''}`}>Monthly</span>
            <div className='tog_block'>
              <span
                className={`tog_btn ${tog && 'month_active'}`}
                onClick={() => setTog(tog === true ? false : true)}
              ></span>
            </div>
            <span className='years'>Yearly</span>
            {/* <span className='offer'>50% off</span> */}
          </div>

          <div className={`pricing_pannel monthly_plan active`}>
            <div className='row'>
              <div className='col-md-3'>
                <div className='pricing_block'>
                  {/* <div className='icon'>
                      {video ? (
                        <img src={Starded1} alt='image' />
                      ) : (
                        <img src={img1} alt='image' />
                      )}
                    </div> */}
                  <div className='pkg_name'>
                    <h3>Standard</h3>
                    <span>Your Daily Dose of Astrology Insights</span>
                  </div>
                  <span className='price text-gradient'>FREE</span>
                  <ul className='benifits'>
                    <li>
                      <p>50 credits a day</p>
                    </li>
                    <li>
                      <p>1 relationship</p>
                    </li>
                  </ul>
                </div>
              </div>
              <div className='col-md-3'>
                <div className='pricing_block'>
                  {/* <div className='icon'>
                      {video ? (
                        <img src={Unlimited1} alt='image' />
                      ) : (
                        <img src={img2} alt='image' />
                      )}
                    </div> */}
                  <div className='pkg_name'>
                    <h3>Premium</h3>
                    <span>Elevate Your Astrological Journey</span>
                  </div>
                  <span className='price text-gradient'>$9.99</span>
                  <ul className='benifits'>
                    <li>
                      <p>500 credits a day</p>
                    </li>
                    <li>
                      <p>10 relationships</p>
                    </li>
                    <li>
                      <p>Add custom relationships</p>
                    </li>
                    <li>
                      <p>Receive tips</p>
                    </li>
                    <li>
                      <p>Private profile option</p>
                    </li>
                  </ul>
                </div>
              </div>
              <div className='col-md-3'>
                <div className='pricing_block'>
                  {/* <div className='icon'>
                      {video ? (
                        <img src={Premium1} alt='image' />
                      ) : (
                        <img src={img3} alt='image' />
                      )}
                    </div> */}
                  <div className='pkg_name'>
                    <h3>Astrologer</h3>
                    <span>Empower Your Astrological Practice</span>
                  </div>
                  <span className='price text-gradient'>$4.99</span>
                  <ul className='benifits'>
                    <li>
                      <p>100 credits a day</p>
                    </li>
                    <li>
                      <p>5 relationships</p>
                    </li>
                    <li>
                      <p>Add custom relationships</p>
                    </li>
                    <li>
                      <p>Receive tips</p>
                    </li>
                  </ul>
                </div>
              </div>
              <div className='col-md-3'>
                <div className='pricing_block'>
                  {/* <div className='icon'>
                      {video ? (
                        <img src={Premium1} alt='image' />
                      ) : (
                        <img src={img3} alt='image' />
                      )}
                    </div> */}
                  <div className='pkg_name'>
                    <h3>Astrologer+</h3>
                    <span>Ultimate Access for Expert Astrologers</span>
                  </div>
                  <span className='price text-gradient'>$29.99</span>
                  <ul className='benifits'>
                    <li>
                      <p>100 credits a day</p>
                    </li>
                    <li>
                      <p>5 relationship</p>
                    </li>
                    <li>
                      <p>Add custom relationships</p>
                    </li>
                    <li>
                      <p>Receive tips</p>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Main;
