import React from 'react';
import { Route, Routes } from 'react-router-dom';
import Footer from '../component/Footer/Main';
import Home from '../component/Home/Main';
import Navbar from '../component/Navbar/Main';

const Routing = () => {
  return (
    <>
      <Navbar />
      <Routes>
        <Route path='/' element={<Home />} />
      </Routes>
      <Footer />
    </>
  );
};

export default Routing;
