import { IconComet, IconMoonStars } from '@tabler/icons-react';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';
import React from 'react';
import OwlCarousel from 'react-owl-carousel';
import frame from '../../../assets/images/mobile_frame_svg.svg';
import screenshot5 from '../../../assets/images/screenshots/clients.png';
import screenshot2 from '../../../assets/images/screenshots/product.png';
import screenshot3 from '../../../assets/images/screenshots/services.png';
import screenshot4 from '../../../assets/images/screenshots/tips.png';
import screenshot1 from '../../../assets/images/screenshots/trending.png';

const Main = ({ gredient }) => {
  const frmae_slider = {
    loop: true,
    margin: 10,
    nav: false,
    autoplay: true,
    smartSpeed: 1500,
    dots: true,
    responsive: {
      0: {
        items: 1,
      },
      600: {
        items: 1,
      },
      1000: {
        items: 1,
      },
    },
  };

  const designBlocks = [
    {
      title: 'Connect with Astrologers',
      content:
        'Unlock deeper insights with our network of expert astrologers. Access personalized birth charts and detailed horoscopes crafted by seasoned professionals.',
    },
    {
      title: 'Engage and Learn',
      content:
        'Join a vibrant community of astrology lovers. Engage with astrologers, share your experiences, and expand your understanding of astrology. With Galactics, you’re not just receiving readings – you’re becoming part of a supportive and knowledgeable community.',
    },
    {
      title: 'Interactive Learning',
      content:
        'Access a wealth of resources and interactive tools to deepen your understanding of astrology and its impact on your life.',
    },
  ];

  return (
    <>
      <section className={`row_am modern_ui_section banner_section`}>
        <div className='container'>
          <div className='row'>
            <div className='col-lg-6'>
              <div className='ui_text'>
                <div
                  className='section_title'
                  data-aos='fade-up'
                  data-aos-duration='1500'
                  data-aos-delay='100'
                >
                  <h2>
                    Connect with <span>Community</span>
                  </h2>
                  <p>
                    Explore the Galactics marketplace to find and purchase
                    custom astrological services from other users. Offer your
                    own expertise to the community, and earn by providing
                    detailed charts and daily horoscopes. This dynamic platform
                    connects you with a global network of astrology enthusiasts,
                    fostering growth and knowledge exchange.
                  </p>
                </div>
                <ul className='design_block'>
                  {designBlocks.map((block, index) => (
                    <li key={index} data-aos='fade-up' data-aos-duration='1500'>
                      <h4>{block.title}</h4>
                      <p>{block.content}</p>
                    </li>
                  ))}
                </ul>
              </div>
            </div>
            <div className='col-lg-6'>
              <div className='banner_slider'>
                <div className='left_icon'>
                  <IconComet
                    size={64}
                    strokeWidth={1}
                    style={{ stroke: 'url(#gradient)' }}
                  />
                  {/* <img src={msg} alt='image' /> */}
                </div>
                <div className='right_icon'>
                  <IconMoonStars
                    size={64}
                    strokeWidth={1}
                    style={{ stroke: 'url(#gradient)' }}
                  />
                  {/* <img src={shield} alt='image' /> */}
                </div>
                <OwlCarousel
                  id='frmae_slider'
                  className='owl-carousel owl-theme owl-loaded owl-drag'
                  {...frmae_slider}
                >
                  <div className='item'>
                    <div className='slider_img'>
                      <img src={screenshot1} alt='image' />
                    </div>
                  </div>
                  <div className='item'>
                    <div className='slider_img'>
                      <img src={screenshot2} alt='image' />
                    </div>
                  </div>
                  <div className='item'>
                    <div className='slider_img'>
                      <img src={screenshot3} alt='image' />
                    </div>
                  </div>
                  <div className='item'>
                    <div className='slider_img'>
                      <img src={screenshot4} alt='image' />
                    </div>
                  </div>
                  <div className='item'>
                    <div className='slider_img'>
                      <img src={screenshot5} alt='image' />
                    </div>
                  </div>
                </OwlCarousel>
                <div className='slider_frame'>
                  <img src={frame} alt='image' />
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Main;
