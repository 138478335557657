import { useCallback, useState } from 'react';
import Particles from 'react-tsparticles';
import { loadFull } from 'tsparticles';

const ParticlesBackground = () => {
  const [options, setOptions] = useState();

  const particlesInit = useCallback(async (engine) => {
    const _options = {
      fullScreen: {
        enable: true,
      },
      fpsLimit: 1000,
      interactivity: {
        events: {
          onClick: {
            enable: true,
            mode: 'push',
          },
          resize: true,
        },
        modes: {
          push: {
            quantity: 1,
          },
          repulse: {
            distance: 200,
            duration: 0.4,
          },
        },
      },
      particles: {
        color: {
          value: '#1098AD',
        },
        links: {
          color: '#66D9E8',
          distance: 150,
          enable: true,
          opacity: 0.8,
          width: 0.5,
        },
        collisions: {
          enable: false,
        },
        move: {
          directions: 'none',
          enable: true,
          outModes: {
            default: 'bounce',
          },
          random: false,
          speed: 1,
          straight: false,
        },
        number: {
          density: {
            enable: true,
            area: 1000,
          },
          value: 30,
        },
        opacity: {
          value: 0.5,
        },
        size: {
          value: { min: 1, max: 3 },
        },
      },
      detectRetina: true,
    };

    setOptions(_options);
    await loadFull(engine);
  }, []);

  return (
    <>
      <div
        style={{
          position: 'absolute',
          top: 0,
          left: 0,
          right: 0,
          bottom: 0,
          zIndex: -1,
          backgroundSize: 'cover',
          backgroundRepeat: 'no-repeat',
          backgroundPosition: 'center center',
        }}
      >
        <Particles init={particlesInit} options={options} />
      </div>
    </>
  );
};

export default ParticlesBackground;
