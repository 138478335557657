import React from 'react';
import { Link } from 'react-router-dom';

import whiteapp from '../../assets/images/appstore_white.png';
import top from '../../assets/images/go_top.png';
import white from '../../assets/images/googleplay_white.png';

const Main = () => {
  return (
    <>
      <section className='newsletter_section' style={{ marginBottom: 200 }}>
        <div className='container'>
          <div className='newsletter_box'>
            <div
              className='section_title'
              data-aos='fade-in'
              data-aos-duration='1500'
              data-aos-delay='100'
            >
              <h2>Subscribe</h2>
              <p>Be the first to recieve all latest post in your inbox</p>
            </div>
            <form
              action=''
              data-aos='fade-in'
              data-aos-duration='1500'
              data-aos-delay='100'
            >
              <div className='form-group'>
                <input
                  type='email'
                  className='form-control'
                  placeholder='Enter your email'
                />
              </div>
              <div className='form-group'>
                <button className='btn'>SUBMIT</button>
              </div>
            </form>
          </div>
        </div>
      </section>

      <footer>
        <div className='top_footer' id='contact' style={{ display: 'none' }}>
          <div
            className='container'
            style={{
              borderTop: 'solid 1px rgba(255, 255, 255, 0.1)',
              paddingTop: 30,
            }}
          >
            <div className='row'>
              <div className='col-lg-4 col-md-6 col-12'>
                <div className='abt_side'>
                  <div className='logo'>
                    <Link
                      to='/'
                      className='navbar-brand text-gradient'
                      style={{ fontFamily: 'Anthu', fontSize: 32 }}
                    >
                      GALACTICS
                    </Link>
                  </div>
                  {/* <div className='links'>
                    <ul>
                      <li>
                        <Link to='/'>Home</Link>
                      </li>
                      <li>
                        <Link to='#features'>Features</Link>
                      </li>
                      <li>
                        <Link to='#pricing'>Pricing</Link>
                      </li>
                      <li>
                        <Link to='#subscribe'>Subscribe</Link>
                      </li>
                    </ul>
                  </div> */}
                  <ul className='social_media'>
                    {/* <li>
                        <Link to='#'>
                          <i className='icofont-facebook'></i>
                        </Link>
                      </li> */}
                    <li>
                      <Link to='#'>
                        <i className='icofont-twitter'></i>
                      </Link>
                    </li>
                    <li>
                      <Link to='#'>
                        <i className='icofont-instagram'></i>
                      </Link>
                    </li>
                    {/* <li>
                        <Link to='#'>
                          <i className='icofont-pinterest'></i>
                        </Link>
                      </li> */}
                  </ul>
                </div>
              </div>
              <div className='col-lg-3 col-md-6 col-12'></div>
              {/* <div className='col-lg-3 col-md-6 col-12'>
                  <div className='links'>
                    <h3>Help & Suport</h3>
                    <ul>
                      <li>
                        <Link to='/faq'>FAQs</Link>
                      </li>
                      <li>
                        <Link to='#'>Support</Link>
                      </li>
                      <li>
                        <Link to='/work'>How it works</Link>
                      </li>
                      <li>
                        <Link to='#'>Terms & conditions</Link>
                      </li>
                      <li>
                        <Link to='#'>Privacy policy</Link>
                      </li>
                    </ul>
                  </div>
                </div> */}
              <div className='col-lg-2 col-md-6 col-12'>
                <div className='try_out'>
                  {/* <h3>Let’s Try Out</h3> */}
                  <ul className='app_btn'>
                    <li>
                      <Link to='#'>
                        <img src={whiteapp} alt='image' />
                      </Link>
                    </li>
                    <li>
                      <Link to='#'>
                        <img src={white} alt='image' />
                      </Link>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className='bottom_footer'>
          <div className='container'>
            <div className='row'>
              <div className='col-md-6' style={{ paddingTop: 10 }}>
                <p>© Copyrights 2024. All rights reserved.</p>
              </div>
              <div className='col-md-6' style={{ textAlign: 'right' }}>
                <div className='logo'>
                  <Link
                    to='/'
                    className='navbar-brand text-gradient'
                    style={{ fontFamily: 'Anthu', fontSize: 32 }}
                  >
                    GALACTICS
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className='go_top'>
          <span>
            <img src={top} alt='image' />
          </span>
        </div>
      </footer>
    </>
  );
};

export default Main;
