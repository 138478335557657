import { IconPlanet, IconSparkles } from '@tabler/icons-react';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';
import React from 'react';
import OwlCarousel from 'react-owl-carousel';
import screen from '../../../assets/images/galactics_screen_1.png';
import frame from '../../../assets/images/mobile_frame_svg.svg';
import screenshot1 from '../../../assets/images/screenshots/astrologer.png';
import screenshot2 from '../../../assets/images/screenshots/astrologer-messages.png';
import screenshot3 from '../../../assets/images/screenshots/relationship-aspects.png';
import screenshot4 from '../../../assets/images/screenshots/alignments.png';
import screenshot5 from '../../../assets/images/screenshots/aspects.png';
import screenshot6 from '../../../assets/images/screenshots/stats.png';

const Main = ({ gredient }) => {
  const frmae_slider = {
    loop: true,
    margin: 10,
    nav: false,
    autoplay: true,
    smartSpeed: 1500,
    dots: true,
    responsive: {
      0: {
        items: 1,
      },
      600: {
        items: 1,
      },
      1000: {
        items: 1,
      },
    },
  };

  const designBlocks = [
    {
      title: 'AI-Powered Insights',
      content:
        'Our advanced AI algorithms analyze your astrological chart to provide accurate, personalized guidance tailored to your unique cosmic blueprint.',
    },
    {
      title: 'Comprehensive Astrological Reports',
      content:
        'Get detailed reports on your birth chart, daily horoscopes, and compatibility with others, all designed to help you understand and optimize your life.',
    },
    {
      title: 'Energy and Wellness Tracking',
      content:
        'Monitor your emotional and physical energy levels to align your actions with the best astrological timings.',
    },
  ];

  return (
    <>
      <section
        id='features'
        className={`row_am modern_ui_section banner_section`}
      >
        <div className='container'>
          <div className='row'>
            <div className='col-lg-6'>
              <div className='banner_slider'>
                <div className='left_icon'>
                  <IconSparkles
                    size={64}
                    strokeWidth={1}
                    style={{ stroke: 'url(#gradient)' }}
                  />
                  {/* <img src={msg} alt='image' /> */}
                </div>
                <div className='right_icon'>
                  <IconPlanet
                    size={64}
                    strokeWidth={1}
                    style={{ stroke: 'url(#gradient)' }}
                  />
                  {/* <img src={shield} alt='image' /> */}
                </div>
                <OwlCarousel
                  id='frmae_slider'
                  className='owl-carousel owl-theme owl-loaded owl-drag'
                  {...frmae_slider}
                >
                  <div className='item'>
                    <div className='slider_img'>
                      <img src={screenshot1} alt='image' />
                    </div>
                  </div>
                  <div className='item'>
                    <div className='slider_img'>
                      <img src={screenshot2} alt='image' />
                    </div>
                  </div>
                  <div className='item'>
                    <div className='slider_img'>
                      <img src={screenshot3} alt='image' />
                    </div>
                  </div>
                  <div className='item'>
                    <div className='slider_img'>
                      <img src={screenshot4} alt='image' />
                    </div>
                  </div>
                  <div className='item'>
                    <div className='slider_img'>
                      <img src={screenshot5} alt='image' />
                    </div>
                  </div>
                  <div className='item'>
                    <div className='slider_img'>
                      <img src={screenshot6} alt='image' />
                    </div>
                  </div>
                </OwlCarousel>
                <div className='slider_frame'>
                  <img src={frame} alt='image' />
                </div>
              </div>

              {/* <div
                className='ui_images'
                data-aos='fade-in'
                data-aos-duration='1500'
              >
                <div className='left_img'>
                  <img
                    className='moving_position_animatin'
                    src={img1}
                    alt='image'
                  />
                </div>
                <div className='right_img'>
                  {[img3, img4, img4].map((img, index) => (
                    <img
                      key={index}
                      className='moving_position_animatin'
                      src={img}
                      alt='image'
                    />
                  ))}
                </div>
              </div> */}
            </div>

            <div className='col-lg-6'>
              <div className='ui_text'>
                <div
                  className='section_title'
                  data-aos='fade-up'
                  data-aos-duration='1500'
                  data-aos-delay='100'
                >
                  <h2>
                    Intelligent Astrology for <span>Everyday Clarity</span>
                  </h2>
                  <p>
                    Galactics uses advanced AI to deliver personalized
                    astrological insights, tailored to your unique profile.
                    Start each day with a clear understanding of cosmic
                    influences and make informed decisions about relationships,
                    career, and personal growth.
                  </p>
                </div>
                <ul className='design_block'>
                  {designBlocks.map((block, index) => (
                    <li key={index} data-aos='fade-up' data-aos-duration='1500'>
                      <h4>{block.title}</h4>
                      <p>{block.content}</p>
                    </li>
                  ))}
                </ul>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Main;
